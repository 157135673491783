import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';
import React from 'react'

type PrivateRouteProps = {
    component: React.ComponentType,
    roles?: string,
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const api = new APICore();
    const loggedInUser = api.getLoggedInUser();
    const navigate = useNavigate();
    /**
     * not logged in so redirect to login page with the return url
     */
    if (api.isUserAuthenticated() === false) {
        return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }
    // const [isAuthenticated, setAuthenticated] = React.useState(() => {
    //     const token = localStorage.getItem("getauthtoken");
    //     return token !== null;
    // });

    // React.useEffect(() => {
    //     let token = JSON.parse(localStorage.getItem('getauthtoken'))
    //     console.log(token)
    //     console.log('hellllllllllllllllll')
    //     if (token ===null) {
    //         console.log('inside')
    //         return <Navigate to={'/account/login'} />;
    //         // navigate("/account/login")
    //         // window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
    //     }
    // }, [])


    // React.useEffect(() => {
    //     if (isAuthenticated === false) {
    //         // return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    //         navigate("/account/login")
    //     }

    // }, [])


    // // check if route is restricted by role
    // if (roles && roles.indexOf(loggedInUser.role) === -1) {
    //     // role not authorised so redirect to home page
    //     return <Navigate to={{ pathname: '/' }} />;
    // }

    return <RouteComponent />;
};

export default PrivateRoute;
