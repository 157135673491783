// @flow
import React from 'react';
import Routes from './routes/Routes';
import {useLocation} from "react-router-dom";
// setup fake backend
import { configureFakeBackend } from './helpers';

// Themes

// For Saas import Saas.scss
import './assets/scss/Saas.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';

// configure fake backend
configureFakeBackend();

type AppProps = {};

/**
 * Main app component
 */
const App = (props: AppProps): React$Element<any> => {

   
    // const location = useLocation();


  
    // React.useEffect(()=>{
    //   let token = localStorage.getItem('getauthtoken');
    //   if(token ==null){
    //     if(location.search.split('=')[1]==undefined){
    //       // window.location.replace("https://sso.mpensystems.com/login?serviceURL=https://api.evci.in/sso")
    //       window.location.replace("abc")
    //     }else{
    //       console.log('sadffffffffffffffffffffffhg')
    //       localStorage.setItem('getauthtoken',JSON.stringify(location.search.split('=')[1]))
    //       setauthtoken(location.search.split('=')[1])
    //     }
    //   }
    // },[])
  
 
   
    return <Routes></Routes>;
};

export default App;
